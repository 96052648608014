import { useRef, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactTable, Checkbox, Tooltip, IconButton, Toggle } from '@crazyegginc/hatch';

import { useSelection, useModal, useMutation } from '/src/hooks';
import { useFilter } from '../notifications-filter-context';
import { NOTIFICATION_TYPES, RESOURCE_META, NOTIFICATIONS_META } from '../constants';
import { formatDate } from '/src/utils/date';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { SORT_ORDER_TYPES } from '../../_global/constants';
import { notificationToggleEnabledMutation } from '../mutations';

import { EditNotificationsModal } from './modals/EditNotificationsModal';
import { DeleteWarningModal } from './modals/DeleteWarningModal';

import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DeleteIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function NotificationsTable({ notifications, queryResult, selectable = true, inEditModal = false }) {
  const { isInitialLoading, hasNextPage, fetchNextPage } = queryResult;
  const {
    setSort,
    setOrder,
    order: { sort, field },
  } = useFilter({ noQueryParams: inEditModal });

  const tableRef = useRef(null);
  const { toggleSelection, setSelection, clearSelection, currentSelection } = useSelection();

  const {
    context: toggleContext,
    mutate: toggleEnabled,
    isLoading: toggleLoading,
  } = useMutation(notificationToggleEnabledMutation);

  const columns = useMemo(() => {
    const cols = [
      {
        header: 'Destination',
        accessorKey: 'destination',
        size: 120,
        cell: ({ row }) => {
          const {
            channel: { type, destination },
          } = row.original;
          const { Icon, displayName } = NOTIFICATIONS_META[type];
          return (
            <div className="flex min-w-0 items-center space-x-2.5">
              <Icon className="h-4 w-4 shrink-0 fill-current text-cadet-blue-500" aria-label={`${displayName}`} />
              <span
                className={classNames('text-body-5 truncate', {
                  'text-black-pearl-500': inEditModal,
                })}
              >
                {destination}
              </span>
            </div>
          );
        },
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Created on',
        accessorKey: 'createdAt',
        size: 50,
        cell: ({ row }) => (
          <div className="flex flex-col">
            <span>{formatDate(row.original.createdAt)}</span>
            <span className="text-caption">by {row.original.createdBy.name}</span>
          </div>
        ),
        meta: {
          align: 'center',
          justify: 'left',
        },
      },
      {
        header: 'Active',
        accessorKey: 'enabled',
        enableSorting: false,
        size: 20,
        cell: ({ row }) => {
          const isLoading = toggleLoading && toggleContext?.id === row.original.id;
          return (
            <Toggle
              labelClasses="!mt-px"
              disabled={isLoading}
              visuallyDisable={false}
              enabled={row.original.enabled}
              setEnabled={(newValue) => {
                toggleEnabled({
                  ids: [row.original.id],
                  enabled: newValue,
                });
              }}
              label={isLoading ? 'Saving...' : row.original.enabled ? 'On' : 'Off'}
              displayLabel={false}
            />
          );
        },
        meta: {
          align: 'center',
          justify: 'center',
        },
      },
      {
        id: 'Actions',
        size: 30,
        enableSorting: false,
        meta: {
          align: 'center',
          padding: '0',
        },
        cell: function ActionsCell({ row }) {
          return <ActionsRow notification={row.original} inEditModal={inEditModal} />;
        },
      },
    ];

    if (!inEditModal) {
      cols.unshift({
        header: () => <div className="ml-[35px]">Name</div>,
        accessorKey: 'resourceName',
        size: 120,
        cell: ({ row }) => {
          const { resource, resourceName, resourceId } = row.original;
          const { Icon, link, displayName } = RESOURCE_META[resource];
          return (
            <div className="flex min-w-0 items-center">
              <Icon className="h-5 w-5 shrink-0 fill-current text-cadet-blue-500" />
              <div className="ml-3.75 min-w-0 flex flex-col">
                <Link className="text-link line-clamp-2 leading-none" to={link(resourceId)}>
                  {resourceName}
                </Link>
                <div className="text-caption leading-tight">{displayName}</div>
              </div>
            </div>
          );
        },
        meta: {
          align: 'center',
          justify: 'left',
        },
      });
    }

    if (selectable) {
      cols.unshift({
        id: 'selection',
        size: 20,
        enableSorting: false,
        meta: {
          align: 'center',
          justify: 'left',
        },
        header: () => (
          <div className="pl-3.75">
            <Checkbox
              checked={currentSelection.length && currentSelection.length === notifications.length ? true : false}
              onChange={() => {
                currentSelection.length === notifications.length ? clearSelection() : setSelection([...notifications]);
              }}
              indeterminate={currentSelection.length > 0 && currentSelection.length < notifications.length}
              title="Toggle all rows selected"
              size="lg"
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="pl-3.75">
            <Checkbox
              checked={currentSelection.findIndex((x) => x.id === row.original.id) > -1}
              onChange={() => toggleSelection(row.original)}
              size="lg"
            />
          </div>
        ),
      });
    }

    return cols;
  }, [
    clearSelection,
    currentSelection,
    notifications,
    selectable,
    setSelection,
    toggleSelection,
    inEditModal,
    toggleLoading,
    toggleEnabled,
    toggleContext?.id,
  ]);

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(field),
      desc: sort === SORT_ORDER_TYPES.DESC,
    };
  }, [field, sort]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setOrder(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setOrder, setSort],
  );

  return (
    <ReactTable
      nested={inEditModal ? true : false}
      fetching={isInitialLoading}
      ref={{ tableRef }}
      onFetchData={onFetchData}
      enableSorting={true}
      sorting={tableSortData}
      columns={columns}
      data={notifications}
      rowPadding={true}
      useVirtualization={true}
      hasNextPage={hasNextPage}
      loadNextPage={fetchNextPage}
    />
  );
}

function ActionsRow({ notification, inEditModal }) {
  const modal = useModal();

  return (
    <div
      className={classNames(
        'invisible flex w-full max-w-[79px] items-center justify-between space-x-1.5 px-1.25 group-hover:visible',
      )}
    >
      <Tooltip tooltipContent="Edit">
        <IconButton
          onClick={() => {
            const { Modal } = NOTIFICATIONS_META[notification.channel.type];
            modal.show(
              <Modal
                notification={notification}
                resourceType={notification.resource}
                resourceId={notification.resourceId}
                closeAfterAction={true}
                BackToModal={inEditModal ? EditNotificationsModal : null}
              />,
            );
          }}
          className="shrink-0 !p-2 text-malibu-500 hover:text-dodger-blue-500"
          icon={<EditIcon aria-label="edit notification" className="h-4 w-4 fill-current" />}
        />
      </Tooltip>

      <Tooltip
        tooltipContent={
          <div className="max-w-[165px]">
            {notification.channel.type === NOTIFICATION_TYPES.ZAPIER
              ? 'Login to Zapier to delete this notification.'
              : 'Delete'}
          </div>
        }
      >
        <IconButton
          disabled={notification.channel.type === NOTIFICATION_TYPES.ZAPIER}
          onClick={() => {
            modal.show(
              <DeleteWarningModal
                id={notification.id}
                resourceType={notification.resource}
                resourceId={notification.resourceId}
                BackToModal={inEditModal ? EditNotificationsModal : null}
              />,
            );
          }}
          className={classNames('shrink-0 !p-2', {
            'text-malibu-500 hover:text-radical-red-500': notification.channel.type !== NOTIFICATION_TYPES.ZAPIER,
            'cursor-not-allowed text-malibu-500/50': notification.channel.type === NOTIFICATION_TYPES.ZAPIER,
          })}
          icon={<DeleteIcon aria-label="delete notification" className={classNames('h-4 w-4 fill-current')} />}
        />
      </Tooltip>
    </div>
  );
}
