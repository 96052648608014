import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Button, Spinner } from '@crazyegginc/hatch';

import { useModal, useSelectedSite, useAuthContext } from '/src/hooks';
import { notificationsListQuery } from '../queries';

import { GetNotifiedModal } from './modals/GetNotifiedModal';
import { EditNotificationsModal } from './modals/EditNotificationsModal';

import { ReactComponent as BellIcon } from '@crazyegginc/hatch/dist/images/icon-bell.svg';
import { ReactComponent as ActiveBellIcon } from '@crazyegginc/hatch/dist/images/icon-bell-active.svg';

export function NotificationsButton({ resourceType, resourceId, size = 'base' }) {
  const { selectedSite } = useSelectedSite();
  const { isSharing } = useAuthContext();
  const modal = useModal();

  const { data, isFetching } = useInfiniteQuery({
    ...notificationsListQuery({
      resourceId: String(resourceId),
      siteId: selectedSite?.id,
    }),
    enabled: Boolean(selectedSite?.id && !isSharing),
  });

  const notifications = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.notificationsList?.notifications ?? [])], []) ?? [],
    [data],
  );

  let Icon = BellIcon;
  let text = 'Get Notified';
  let ModalComp = GetNotifiedModal;

  if (notifications.length > 0) {
    Icon = ActiveBellIcon;
    text = 'Edit Notifications';
    ModalComp = EditNotificationsModal;
  }

  if (isSharing) return null;

  return (
    <Button
      variant="secondary"
      onClick={() => modal.show(<ModalComp resourceType={resourceType} resourceId={resourceId} />)}
      className="shrink-0 whitespace-nowrap"
      size={size}
      disabled={isFetching}
    >
      {isFetching ? (
        <>
          <Spinner />
          <span className="ml-2">Loading</span>
        </>
      ) : (
        <>
          <Icon className="mr-2.5 h-4 w-4 fill-current shrink-0" />
          {text}
        </>
      )}
    </Button>
  );
}
