import { useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';

import { useMutation, useNotifications, useDebounce, usePrevious, useModal, usePermissions } from '/src/hooks';
import { setRecordingSettings } from '/src/features/options/mutations';

import { Select, Tooltip } from '@crazyegginc/hatch';
import { inflect } from '/src/utils/string';
import {
  getInitialValues,
  calculateRecordingRatio,
  numberToPercentage,
  percentageToNumber,
  checkSamplingType,
} from '/src/features/options/utils';
import { SiteSettingsModal } from '/src/features/options/components/modals/SiteSettingsModal';
import { SiteSettingsColumnSection } from '/src/features/options/components/SiteSettingsColumnSection';

import { FEATURES } from '/src/features/_global/constants';
import { SAMPLING_TYPE, NUMBER_TYPE } from '/src/features/options/constants';

import { ReactComponent as WarningIcon } from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg';

function AutoSave({ func }) {
  const formik = useFormikContext();
  const { values, isValid, dirty, isSubmitting } = formik;

  const debounceValue = useDebounce(values.value, 1250);
  const prevDebouncedValue = usePrevious(debounceValue);

  useEffect(() => {
    if (isValid && dirty && !isSubmitting && prevDebouncedValue !== debounceValue) {
      func();
    }
  }, [func, formik, debounceValue, dirty, isSubmitting, isValid, prevDebouncedValue]);

  return null;
}

function ErrorComponent({
  error,
  isSamplingAutomatic,
  isNumberTypePercentage,
  samplingRecordingRatio,
  samplingEstimated,
  value,
}) {
  let text = '';

  if (!error) {
    if (isSamplingAutomatic) {
      const renderNumber = Math.round(
        percentageToNumber(calculateRecordingRatio(samplingRecordingRatio), samplingEstimated),
      );
      text = `${renderNumber.toLocaleString()} est. ${inflect('recording', renderNumber)} /mo`;
    } else {
      if (isNumberTypePercentage) {
        const renderNumber = Math.round(percentageToNumber(value, samplingEstimated));
        text = `${renderNumber.toLocaleString()} est. ${inflect('recording', renderNumber)} /mo`;
      } else {
        text = `${numberToPercentage(value, samplingEstimated) >= 100 ? 100 : numberToPercentage(value, samplingEstimated).toLocaleString()}% sampling rate`;
      }
    }
  } else {
    text = error;
  }

  if (isSamplingAutomatic || isNumberTypePercentage) {
    return (
      <div className="flex w-full">
        <Tooltip
          tooltipContent={
            <div className="w-[300px] p-0.5 leading-tight">
              <p>The sampling rate is automatically adjusted to meet the quota.</p>
            </div>
          }
          placement="bottom"
          show={!error && isSamplingAutomatic}
        >
          <span
            className={classNames('text-caption  text-2xs  font-normal cursor-default', {
              'absolute top-0 left-0 leading-none text-carnation-500 pt-0.5': error,
              'underline decoration-[#7786A0] decoration-dashed underline-offset-4 text-[#7786A0]':
                !error && isSamplingAutomatic,
            })}
          >
            {text}{' '}
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <span
        className={classNames('text-caption', {
          'absolute top-0 left-0 leading-none text-carnation-500 pt-0.5': error,
        })}
      >
        {text}{' '}
      </span>
      {Math.round(numberToPercentage(value, samplingEstimated)) > 100 &&
        Math.round(percentageToNumber(100, samplingEstimated)) !== value && (
          <Tooltip
            interactive
            tooltipContent={
              <div className="w-[300px] p-0.5 leading-tight">
                <p>
                  Your estimated targeted visitors is only{' '}
                  {Math.round(percentageToNumber(100, samplingEstimated)).toLocaleString()}/mo so you might not receive{' '}
                  {value.toLocaleString()} {inflect('recording', value)}/mo. We will record 100% of visitors so you get
                  the most possible number of recordings.
                </p>
              </div>
            }
            placement="bottom"
          >
            <WarningIcon
              className="ml-1.5 h-4 w-4 fill-current text-dandelion-500"
              aria-label="hover for more info on over estimate"
            />
          </Tooltip>
        )}
    </div>
  );
}

function VisitorInfoComponent({ isPercentage, siteSettings }) {
  const modal = useModal();
  const permissions = usePermissions();
  const canEditSites = permissions.can('edit', FEATURES.SITE_SETTINGS).allowed;
  const urlBlockedRules = getInitialValues('url', siteSettings?.recordingSettings?.urlMatchingRules);

  return (
    <div className="text-caption ml-2.5 flex flex-1 items-center leading-none">
      {isPercentage ? 'of' : 'from'}{' '}
      {siteSettings?.recordingSettings?.pageTargetingEnabled ||
      (siteSettings?.recordingSettings?.endSessionOnBlockedUrl && urlBlockedRules.length > 0) ? (
        <button
          onClick={() => modal.show(<SiteSettingsModal site={siteSettings} />)}
          disabled={!canEditSites}
          className="text-link !text-2xs contents"
        >
          targeted visitors
        </button>
      ) : (
        'all visitors'
      )}{' '}
      to this site
    </div>
  );
}

export function SiteSettingsSamplingInputs({
  siteId,
  samplingType,
  samplingValue,
  samplingRecordingRatio,
  samplingEstimatedMaxRecordingsDay,
  samplingEstimatedVisitsDay,
  name,
  siteSettings,
}) {
  /*
    NOTE:
      For initial data
     - AUTO: check the recordingRatio
     - RATE or STATIC: check the samplingValue
  */

  const returnSamplingType = checkSamplingType(samplingType);
  const returnEstimatedVisit =
    samplingEstimatedMaxRecordingsDay && samplingEstimatedMaxRecordingsDay > 0
      ? samplingEstimatedMaxRecordingsDay
      : samplingEstimatedVisitsDay;
  const isSamplingAutomatic = returnSamplingType === SAMPLING_TYPE.AUTO;
  const isNumberTypePercentage = returnSamplingType === NUMBER_TYPE.RATE;
  const initialValuesValue =
    returnSamplingType === SAMPLING_TYPE.AUTO ? calculateRecordingRatio(samplingRecordingRatio) : (samplingValue ?? 0);

  const initialValue = {
    type: returnSamplingType,
    value: initialValuesValue,
  };

  const [staticInputOldValue, setStaticInputOldValue] = useState(() => {
    const haveSessionStorage = window.sessionStorage.getItem(`siteSettings-${siteId}`);
    if (haveSessionStorage) {
      return haveSessionStorage;
    } else {
      if (returnSamplingType === NUMBER_TYPE.STATIC) {
        if (Math.round(numberToPercentage(initialValuesValue, returnEstimatedVisit)) > 100) {
          window.sessionStorage.setItem(`siteSettings-${siteId}`, initialValuesValue);
          return initialValuesValue;
        } else {
          return 0;
        }
      }
    }
  });

  const validationSchema = yup.object().shape({
    type: yup.string().oneOf([...Object.values(SAMPLING_TYPE), ...Object.values(NUMBER_TYPE)]),
    value: yup
      .number()
      .when('type', {
        is: (value) => value === NUMBER_TYPE.RATE,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .max(100, 'Should not exceed 100.')
          .test('is-decimal', 'The maximum number of decimal places is two.', (value) => {
            if (value != undefined) {
              return /^\d+(\.\d{0,2})?$/.test(value);
            }
            return true;
          }),
      })
      .when('type', {
        is: (value) => value === NUMBER_TYPE.STATIC,
        then: yup
          .number()
          .required('Please provide a valid number.')
          .typeError('Please provide a valid number.')
          .integer('Please provide a valid number.')
          .positive('Please provide a valid number.'),
      }),
  });

  const updateRecordingSettings = useMutation(setRecordingSettings);
  const notifications = useNotifications();

  const { mutate } = updateRecordingSettings;

  const options = [
    {
      value: SAMPLING_TYPE.AUTO,
      label: 'Automatic',
    },
    {
      value: SAMPLING_TYPE.MANUAL,
      label: 'Manual',
    },
  ];

  const numTypeOption = [
    {
      value: NUMBER_TYPE.RATE,
      label: '%',
    },
    {
      value: NUMBER_TYPE.STATIC,
      label: '#',
    },
  ];

  const handleSamplingUpdate = useCallback(
    (type, value) => {
      mutate(
        {
          siteId,
          samplingType: type,
          samplingValue: type === NUMBER_TYPE.RATE ? parseFloat(value) : Math.round(parseInt(value)),
        },
        {
          onError: (error) => {
            notifications.error({
              content: `Sampling update failed for ${name}.`,
              timeout: 3000,
              context: { error },
            });
          },
        },
      );
    },
    [notifications, siteId, mutate, name],
  );

  function returnValueToBeSend(type, num) {
    if (type === NUMBER_TYPE.RATE) {
      return numberToPercentage(num, returnEstimatedVisit) >= 100 ? 100 : numberToPercentage(num, returnEstimatedVisit);
    } else if (type === NUMBER_TYPE.STATIC) {
      const isStaticInputOldValue =
        parseInt(staticInputOldValue) > 0
          ? numberToPercentage(parseInt(staticInputOldValue), returnEstimatedVisit)
          : num;
      return Math.round(percentageToNumber(isStaticInputOldValue, returnEstimatedVisit));
    } else {
      return num;
    }
  }

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSamplingUpdate(values.type, values.value);
      }}
    >
      {({ values, errors, setFieldValue, resetForm }) => {
        const samplingNumber = values?.value ?? 0;
        const isValueTypeAuto = values.type === SAMPLING_TYPE.AUTO;

        return (
          <Form className="relative flex w-full">
            <SiteSettingsColumnSection placement="left">
              <div className="flex flex-col">
                <Select
                  aria-label={values.type}
                  name="samplingType"
                  value={
                    values.type === NUMBER_TYPE.RATE || values.type === NUMBER_TYPE.STATIC
                      ? SAMPLING_TYPE.MANUAL
                      : SAMPLING_TYPE.AUTO
                  }
                  options={options}
                  disabled={!!errors.value}
                  onChange={(type) => {
                    const sendValue =
                      values.type === NUMBER_TYPE.STATIC
                        ? numberToPercentage(values.value, returnEstimatedVisit)
                        : samplingNumber;

                    mutate(
                      {
                        siteId,
                        samplingType: type === SAMPLING_TYPE.MANUAL ? NUMBER_TYPE.RATE : SAMPLING_TYPE.AUTO,
                        samplingValue: parseFloat(sendValue),
                      },
                      {
                        onError: (error) => {
                          notifications.error({
                            content: `Sampling update failed for ${name}.`,
                            timeout: 3000,
                            context: { error },
                          });

                          resetForm();
                        },
                      },
                    );
                  }}
                />
              </div>
            </SiteSettingsColumnSection>
            <SiteSettingsColumnSection placement="right">
              <div className="relative flex">
                <div className="flex min-w-[123px] w-1/2">
                  <div className="flex-1">
                    <NumericFormat
                      aria-label={`number ${name}`}
                      name={`number ${siteId}`}
                      id={`number ${siteId}`}
                      value={samplingNumber}
                      thousandSeparator=","
                      onValueChange={(field) => {
                        const { value } = field;
                        const currentValuePercentage = numberToPercentage(value, returnEstimatedVisit);

                        if (values.type === NUMBER_TYPE.STATIC) {
                          if (currentValuePercentage >= 100) {
                            window.sessionStorage.setItem(`siteSettings-${siteId}`, value);
                            setStaticInputOldValue(value);
                          } else {
                            window.sessionStorage.setItem(`siteSettings-${siteId}`, 0);
                            setStaticInputOldValue(0);
                          }
                        }
                        setFieldValue('value', value);
                      }}
                      disabled={isSamplingAutomatic || isValueTypeAuto}
                      className={classNames(
                        'rounded-br-none rounded-tr-none !pl-2.5 !pr-2 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none text-body-2 w-full rounded border focus:outline-none h-[35px] border-mystic-500 focus:border-dodger-blue-500 bg-white placeholder-lynch-500',
                        {
                          '!border-carnation-500': errors.value,
                        },
                      )}
                    />
                  </div>
                  <Select
                    aria-label={`type ${values.type}`}
                    name="numberType"
                    value={
                      isValueTypeAuto || values.type === NUMBER_TYPE.RATE || values.type === SAMPLING_TYPE.MANUAL
                        ? NUMBER_TYPE.RATE
                        : NUMBER_TYPE.STATIC
                    }
                    options={numTypeOption}
                    onChange={(type) => {
                      if (type !== values.type) {
                        const numberValue = returnValueToBeSend(type, values.value);

                        mutate(
                          {
                            siteId,
                            samplingType: type,
                            samplingValue: numberValue,
                          },
                          {
                            onError: (error) => {
                              notifications.error({
                                content: `Sampling update failed for ${name}.`,
                                timeout: 3000,
                                context: { error },
                              });

                              resetForm();
                            },
                          },
                        );
                      }
                    }}
                    style={{
                      background: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? '#e1e6ef' : '#e5f2fc'}`,
                      borderColor: '#e1e6ef',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderLeft: '0px',
                      cursor: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? 'default' : 'pointer'}`,
                      color: `${isSamplingAutomatic || isValueTypeAuto || errors.value ? '#69768d' : '#0086e6'}`,
                      fontWeight: 'bold',
                      width: '60px',
                    }}
                    disabled={isSamplingAutomatic || isValueTypeAuto || !!errors.value}
                  />
                </div>

                <VisitorInfoComponent
                  isPercentage={isNumberTypePercentage || isSamplingAutomatic}
                  siteSettings={siteSettings}
                />
              </div>
              <div className="absolute -bottom-[3px] w-full h-[20px]">
                <ErrorComponent
                  error={errors.value}
                  isSamplingAutomatic={isSamplingAutomatic}
                  isNumberTypePercentage={isNumberTypePercentage}
                  samplingRecordingRatio={samplingRecordingRatio}
                  samplingEstimated={returnEstimatedVisit}
                  value={values.value}
                />
              </div>
            </SiteSettingsColumnSection>
            <AutoSave func={() => handleSamplingUpdate(values.type, values.value)} />
          </Form>
        );
      }}
    </Formik>
  );
}
