import { Link } from 'react-router-dom';
import classNames from 'classnames';

//import { ReactComponent as ArrowIcon } from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg';

export function Section({ children, className, ...props }) {
  return (
    <section
      className={classNames('flex w-full flex-col rounded-[5px] bg-white border border-mystic-500 shadow', className)}
      {...props}
    >
      {children}
    </section>
  );
}

function SectionHeader({ children, className, icon: Icon, color, to }) {
  return (
    <div className="flex h-[60px] items-center px-5 border-b border-b-mystic-500 w-full shrink-0">
      <div>
        <Link
          to={to}
          className={classNames('text-header-4 flex items-center space-x-3 self-start hover:underline', className)}
        >
          {Icon && <Icon className={classNames('h-4 w-4 fill-current shrink-0', color)} />}
          {children}
        </Link>
      </div>
    </div>
  );
}

function SectionBody({ children, className }) {
  return <div className={classNames('text-body-2 flex grow flex-col', className)}>{children}</div>;
}

function SeeMore(/*{  ...props}*/) {
  return null;
  /*return (
    <div className="flex w-full grow items-end justify-end">
      <Link className="text-link flex items-center" {...props}>
        More...
        <ArrowIcon className="ml-1 h-2.5 w-2.5 rotate-90 fill-current" />
      </Link>
    </div>
  );*/
}

Section.Header = SectionHeader;
Section.Body = SectionBody;
Section.SeeMore = SeeMore;
