import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const auditLogFeatureKeys = createQueryKeys('auditLogFeature', {
  auditableActionList: ({ filter }) => [{ filter }],
});

export const auditableActionListQuery = ({ filter }) => ({
  ...auditLogFeatureKeys.auditableActionList({ filter }),
  getNextPageParam: (lastPage) =>
    lastPage.auditableActionList.hasNextPage ? lastPage.auditableActionList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query AuditableActionList($filter: AuditableActionListFilter!, $cursor: String) {
        auditableActionList(filter: $filter, cursor: $cursor) {
          nextPageCursor
          hasNextPage
          auditableActions {
            __typename
            ... on AbTestRatioChange {
              userId
              guestId
              guestEmail
              changeType
              flowId
              oldRatios
              newRatios
              currentViews
              currentConversions
              admin
              timestamp
            }
            ... on BillingChange {
              userId
              guestId
              guestEmail
              newBillingDetails
              oldBillingDetails
              timestamp
            }
            ... on DeletedAbTest {
              userId
              guestId
              guestEmail
              flowId
              timestamp
            }
            ... on DeletedRecordings {
              userId
              guestId
              guestEmail
              recordingIds
              timestamp
            }
            ... on DeletedSite {
              userId
              guestId
              guestEmail
              siteName
              siteId
              admin
              timestamp
            }
            ... on DeletedSnapshots {
              userId
              guestId
              guestEmail
              snapshotIds
              timestamp
            }
            ... on DuplicatedAbTest {
              userId
              guestId
              guestEmail
              flowId
              timestamp
            }
            ... on InternalUrlVisited {
              userId
              guestId
              guestEmail
              url
              admin
              timestamp
              url
            }
            ... on RecordingSettingsChange {
              userId
              guestId
              guestEmail
              siteId
              oldRecordingSettings
              newRecordingSettings
              timestamp
            }
            ... on SnapshotSettingsChange {
              changes
              guestEmail
              guestId
              siteId
              timestamp
              userId
            }
            ... on SnapshotsCreated {
              userId
              guestId
              guestEmail
              userName
              snapshotNames
              timestamp
            }
            ... on DeletedSurvey {
              userId
              guestId
              guestEmail
              surveyId
              timestamp
              surveyName
            }
            ... on SurveyCreated {
              guestEmail
              guestId
              userId
              surveyId
              timestamp
              userId
              survey {
                active
                content
                name
                siteId
              }
            }
            ... on SurveyEdited {
              changes
              guestEmail
              guestId
              userId
              surveyId
              timestamp
              userId
              survey {
                active
                content
                createdAt
                createdBy
                hasData
                name
              }
            }
            ... on SurveyToggled {
              guestEmail
              guestId
              userId
              newStatus
              surveyId
              timestamp
              userId
              survey {
                name
              }
            }
            ... on SurveyViewResponses {
              guestEmail
              guestId
              surveyId
              timestamp
              userId
              survey {
                active
                content
                createdAt
                createdBy
                name
                totalViews
              }
            }
          }
        }
      }
    `,
  },
});
