import { useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import ccType, { types as CARD_TYPES, getTypeInfo } from 'credit-card-type';
import { Tooltip, Button, Input, Select, Spinner, Autocomplete } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { NO_COUNTRY, NO_MONTH, NO_YEAR } from '../../constants';
import { createOrUpdateCCMutation } from '../../mutations';

import { ReactComponent as InfoIcon } from '@crazyegginc/hatch/dist/images/icon-info-circle-outline.svg';
import { ReactComponent as CVV3Icon } from '@crazyegginc/hatch/dist/images/illustration-cvv-3digit.svg';
import { ReactComponent as CVV4Icon } from '@crazyegginc/hatch/dist/images/illustration-cvv-4digit.svg';
import { ReactComponent as AmexIcon } from '@crazyegginc/hatch/dist/images/logo-amex.svg';
import { ReactComponent as DiscoverIcon } from '@crazyegginc/hatch/dist/images/logo-discover.svg';
import { ReactComponent as MastercardIcon } from '@crazyegginc/hatch/dist/images/logo-mastercard.svg';
import { ReactComponent as VisaIcon } from '@crazyegginc/hatch/dist/images/logo-visa.svg';

const year = new Date().getFullYear();
const yearOptions = [
  { value: NO_YEAR },
  ...Array.apply(null, new Array(15)).map((_, i) => ({
    value: String(i + year),
  })),
];
const monthOptions = [
  { value: NO_MONTH },
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => ({ value: String(x).padStart(2, '0') })),
];

const validationSchema = yup.object().shape({
  ccFirstName: yup
    .string()
    .required('First name is required.')
    .matches(/^[A-Za-z 0-9.,-]*$/, 'Please remove any special characters.'),
  ccLastName: yup
    .string()
    .required('Last name is required.')
    .matches(/^[A-Za-z 0-9.,-]*$/, 'Please remove any special characters.'),
  ccExpirationYear: yup.string().required('Required.').notOneOf([NO_YEAR], 'Required.'),
  ccExpirationMonth: yup.string().required('Required.').notOneOf([NO_MONTH], 'Required.'),
  ccNumber: yup.string().required('Please provide a card number.').min(13, 'Please provide a valid card number.'),
  ccSecurityCode: yup.string().required('Required.').min(3, 'Invalid.').max(4, 'Invalid.'),
  ccAddress: yup.object().shape(
    {
      address: yup
        .string()
        .required('Please provide an address.')
        .max(60, 'Address cannot exceed ${max} characters.')
        .matches(/[a-zA-Z]/, 'Please provide a valid address.')
        .matches(/^[A-Za-z 0-9.,-]*$/, 'Please remove any special characters.'),
      city: yup
        .string()
        .required('Please provide a city.')
        .max(50, 'City name cannot exceed ${max} characters.')
        .matches(/^[A-Za-z 0-9.,-]*$/, 'Please remove any special characters.'),
      stateOrProvince: yup
        .string()
        .nullable(true)
        .max(50, 'State or province cannot exceed ${max} characters.')
        .matches(/^[A-Za-z 0-9.,-]*$/, 'Please remove any special characters.')
        .when(['country'], (country, schema) => {
          if (!['United States', 'Canada', 'Australia'].includes(country)) {
            return schema;
          } else {
            return schema.required('Please provide a state.');
          }
        }),
      zipOrPostalCode: yup
        .string()
        .nullable(true)
        .max(50, 'ZIP / Postal code cannot exceed ${max} characters.')
        .when(['country'], (country, schema) => {
          if (!['United States', 'Canada', 'Australia'].includes(country)) {
            return schema;
          } else {
            return schema.required('Please provide a ZIP / Postal code.');
          }
        }),
      country: yup
        .string()
        .nullable(true)
        .required('Please select a country.')
        .notOneOf([NO_COUNTRY], 'Please select a country.'),
    },
    [
      ['stateOrProvince', 'country'],
      ['zipOrPostalCode', 'country'],
    ],
  ),
});

export function CreditCardModal({ data, countries }) {
  const countryOptions = useMemo(() => [{ value: NO_COUNTRY }, ...countries.map((c) => ({ value: c }))], [countries]);
  const modal = useModal();
  const notifications = useNotifications();

  const { mutate: mutateCC, error } = useMutation(createOrUpdateCCMutation);

  const dateError = error?.graphQLErrors?.some((e) =>
    e.originalError?.message?.match(/card_expiration_month: must be in the future/),
  )
    ? 'Date has to be in the future.'
    : null;

  const cardNumberError = error?.graphQLErrors?.some((e) => e.originalError?.message?.match(/card_number:/))
    ? 'Please check your payment information.'
    : null;
  //?.originalError.message.replace(/card_number: (.*)$/, '$1');

  const stateOrProvinceError = error?.graphQLErrors?.some((e) =>
    e.originalError?.message?.match(/state_or_province:.*must be a valid state or province/),
  )
    ? 'Please provide a valid state or province.'
    : null;
  const zipOrPostalCodeError = error?.graphQLErrors?.some((e) =>
    e.originalError?.message?.match(/zip_or_postal_code:.*must be a valid zip or postal code/),
  )
    ? 'Please provide a valid ZIP or Postal code.'
    : null;

  const haveInputError = [dateError, cardNumberError, stateOrProvinceError, zipOrPostalCodeError].some(Boolean);

  return (
    <Modal dialogClassName="!w-[550px]" data-testid="ccModal">
      <Modal.Title>{data.ccNumberSanitized ? 'Edit payment information' : 'Add a credit card'}</Modal.Title>
      <Formik
        initialValues={{
          ccNumber: '',
          ccSecurityCode: '',
          ccFirstName: data.ccFirstName ?? '',
          ccLastName: data.ccLastName ?? '',
          ccExpirationYear: NO_YEAR,
          ccExpirationMonth: NO_MONTH,
          ccAddress: {
            address: data.ccAddress.address ?? '',
            city: data.ccAddress.city ?? '',
            stateOrProvince: data.ccAddress.stateOrProvince ?? '',
            zipOrPostalCode: data.ccAddress.zipOrPostalCode ?? '',
            country: data.ccAddress.country ?? NO_COUNTRY,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          mutateCC(
            {
              firstName: values.ccFirstName ?? null,
              lastName: values.ccLastName ?? null,
              cardNumber: values.ccNumber ?? null,
              cardExpirationYear: values.ccExpirationYear ?? null,
              cardExpirationMonth: values.ccExpirationMonth ?? null,
              cardSecurityCode: values.ccSecurityCode ?? null,
              address: values.ccAddress.address || null,
              city: values.ccAddress.city || null,
              stateOrProvince: values.ccAddress.stateOrProvince || null,
              zipOrPostalCode: values.ccAddress.zipOrPostalCode || null,
              country: values.ccAddress.country === NO_COUNTRY ? null : values.ccAddress.country,
            },
            {
              onError: () => {
                notifications.error({
                  title: 'Failed to update payment info',
                  content: 'Please fix the errors and try again.',
                  timeout: 4000,
                  skipHoneybadger: haveInputError,
                });
              },
              onSuccess: () => {
                notifications.success({ content: 'Payment information updated successfully.', timeout: 3000 });
                modal.close();
              },
              onSettled: () => {
                actions.setSubmitting(false);
              },
            },
          );
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
          const ccTypes = ccType(values.ccNumber);
          const cardType = ccTypes.length > 1 ? null : ccTypes[0]?.type;
          return (
            <Form className="w-full max-w-3xl space-y-2.5">
              <div className="flex w-full space-x-2.5">
                <div className="relative w-full">
                  <Input
                    name="ccNumber"
                    placeholder="Credit card number"
                    value={formatCCNumber(values.ccNumber, cardType)}
                    onChange={(e) => handleChange('ccNumber')(e.target.value.replaceAll(/\s/g, ''))}
                    onBlur={handleBlur}
                    error={(touched.ccNumber && errors.ccNumber ? errors.ccNumber : null) ?? cardNumberError}
                    rightIcon={
                      <div className="flex items-center space-x-1">
                        {(!values.ccNumber || cardType === CARD_TYPES.VISA) && (
                          <VisaIcon className="w-[30px]" aria-label="visa" />
                        )}
                        {(!values.ccNumber || cardType === CARD_TYPES.AMERICAN_EXPRESS) && (
                          <AmexIcon className="w-[30px]" aria-label="amex" />
                        )}
                        {(!values.ccNumber || cardType === CARD_TYPES.DISCOVER) && (
                          <DiscoverIcon className="w-[30px]" aria-label="discover" />
                        )}
                        {(!values.ccNumber || cardType === CARD_TYPES.MASTERCARD) && (
                          <MastercardIcon className="w-[30px]" aria-label="mastercard" />
                        )}
                      </div>
                    }
                  />
                </div>
                <div className="relative w-24 shrink-0">
                  <Input
                    name="ccSecurityCode"
                    placeholder="CVV"
                    value={values.ccSecurityCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ccSecurityCode && errors.ccSecurityCode ? errors.ccSecurityCode : null}
                    rightIcon={
                      <Tooltip
                        tooltipContent={
                          <div className="text-xs font-normal">
                            <div className="flex items-center">
                              <CVV3Icon aria-label="cvv" className="mr-2.5 w-9 fill-current text-cadet-blue-500" />3
                              digits on back
                            </div>
                            <div className="flex items-center">
                              <CVV4Icon aria-label="cvv amex" className="mr-2.5 w-9 fill-current text-cadet-blue-500" />
                              4 digits on front (AMEX)
                            </div>
                          </div>
                        }
                        placement="top"
                        darkTheme={false}
                        containerStyle={{ zIndex: 99999 }}
                        offset={15}
                      >
                        <InfoIcon aria-label="cvv info" className="h-4 w-4 fill-current text-cadet-blue-500" />
                      </Tooltip>
                    }
                  />
                </div>
              </div>
              <div className="flex space-x-2.5">
                <Input
                  name="ccFirstName"
                  placeholder="First name"
                  value={values.ccFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ccFirstName && errors.ccFirstName ? errors.ccFirstName : null}
                />
                <Input
                  name="ccLastName"
                  placeholder="Last name"
                  value={values.ccLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ccLastName && errors.ccLastName ? errors.ccLastName : null}
                />
                <div className="w-[70px] shrink-0">
                  <Select
                    options={monthOptions}
                    value={values.ccExpirationMonth}
                    onChange={(value) => {
                      setFieldValue('ccExpirationMonth', value);
                    }}
                    renderInPortal={true}
                    popperClassName="!z-[100000]"
                    error={
                      (touched.ccExpirationMonth && errors.ccExpirationMonth ? errors.ccExpirationMonth : null) ??
                      dateError
                    }
                    aria-label="Expiration month"
                  />
                </div>
                <div className="w-24 shrink-0">
                  <Select
                    options={yearOptions}
                    value={values.ccExpirationYear}
                    onChange={(value) => {
                      setFieldValue('ccExpirationYear', value);
                    }}
                    renderInPortal={true}
                    popperClassName="!z-[100000]"
                    error={
                      ((touched.ccExpirationYear && errors.ccExpirationYear ? errors.ccExpirationYear : null) ??
                      dateError)
                        ? ' '
                        : null
                    }
                    aria-label="Expiration year"
                  />
                </div>
              </div>
              <Input
                name="ccAddress.address"
                placeholder="Address"
                value={values.ccAddress.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.ccAddress?.address && errors.ccAddress?.address ? errors.ccAddress?.address : null}
              />
              <div className="flex space-x-2.5">
                <div className="w-1/2">
                  <Input
                    name="ccAddress.city"
                    placeholder="City"
                    value={values.ccAddress.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ccAddress?.city && errors.ccAddress?.city ? errors.ccAddress?.city : null}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    name="ccAddress.stateOrProvince"
                    placeholder="State or Province"
                    value={values.ccAddress.stateOrProvince}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      (touched.ccAddress?.stateOrProvince && errors.ccAddress?.stateOrProvince
                        ? errors.ccAddress?.stateOrProvince
                        : null) ?? stateOrProvinceError
                    }
                  />
                </div>
              </div>
              <div className="flex space-x-2.5">
                <div className="w-1/2">
                  <Input
                    name="ccAddress.zipOrPostalCode"
                    placeholder="ZIP / Postal code"
                    value={values.ccAddress.zipOrPostalCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      (touched.ccAddress?.zipOrPostalCode && errors.ccAddress?.zipOrPostalCode
                        ? errors.ccAddress?.zipOrPostalCode
                        : null) ?? zipOrPostalCodeError
                    }
                  />
                </div>
                <div className="h-[35px] w-1/2">
                  <Autocomplete
                    options={countryOptions}
                    value={values.ccAddress.country}
                    onChange={(value) => setFieldValue('ccAddress.country', value)}
                    error={touched.ccAddress?.country && errors.ccAddress?.country ? errors.ccAddress?.country : null}
                    renderInPortal={true}
                    popperClassName="!z-[100000]"
                    aria-label="Country"
                  />
                </div>
              </div>
              <div className="!mt-7 flex items-center space-x-2.5">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <Spinner className="mr-2.5 h-4 w-4 text-lynch-500" />
                      Verifying...
                    </div>
                  ) : (
                    'Confirm payment info'
                  )}
                </Button>
                <Button variant="cancel" onClick={() => modal.close()}>
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

function formatCCNumber(number, type) {
  const card = getTypeInfo(type);

  if (card) {
    var offsets = [].concat(0, card.gaps, number.length);
    var components = [];

    for (var i = 0; offsets[i] < number.length; i++) {
      var start = offsets[i];
      var end = Math.min(offsets[i + 1], number.length);
      components.push(number.substring(start, end));
    }

    return components.join(' ');
  }

  return number.replaceAll(/([\d]{4})/g, '$1 ').replace(/\s$/, '');
}
