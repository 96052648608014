import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const webAnalyticsFeatureKeys = createQueryKeys('webAnalyticsFeature', {
  report: ({ metrics, filters, dimension, order, direction }) => [
    {
      metrics,
      filters,
      dimension,
      order,
      direction,
    },
  ],
});

export const webAnalyticsQuery = ({ metrics, filters, dimension, order, direction }) => ({
  ...webAnalyticsFeatureKeys.report({ metrics, filters, dimension, order, direction }),
  meta: {
    query: gql`
      query WebAnalytics(
        $metrics: [Metric!]!
        $filters: FilterInput
        $dimension: Dimension!
        $order: Metric!
        $direction: Direction!
      ) {
        report(metrics: $metrics, filters: $filters, dimension: $dimension, order: $order, direction: $direction) {
          ${dimension.toLowerCase()}
          clicks
          visits
          conversions
          pageViews
          uniqueVisits
        }
      }
    `,
  },
});
