import { useAuthContext } from '/src/hooks';

import { CAPABILITY_REASONS } from '/src/features/_global/constants';
import { PERMISSION_CRITERIAS } from '/src/features/_global/constants';

import { ACCOUNT_PERMISSIONS } from '/src/features/account/permissions';
import { LIVE_ACTIVITY_PERMISSIONS } from '/src/features/live-activity/permissions';
import { SNAPSHOTS_PERMISSIONS } from '/src/features/snapshots/permissions';
import { RECORDINGS_PERMISSIONS } from '/src/features/recordings/permissions';
import { GOALS_PERMISSIONS } from '/src/features/goals/permissions';
import { AB_TESTING_PERMISSIONS } from '/src/features/ab-testing/permissions';
import { TRAFFIC_ANALYSIS_PERMISSIONS } from '/src/features/traffic-analysis/permissions';
import { ERRORS_PERMISSIONS } from '/src/features/errors/permissions';
import { ADDONS_PERMISSIONS } from '/src/features/addons/permissions';
import { OPTIONS_PERMISSIONS } from '/src/features/options/permissions';
import { COMMENTING_PERMISSIONS } from '/src/features/commenting/permissions';
import { AUDIT_LOG_PERMISSIONS } from '/src/features/audit-log/permissions';
import { INSTALLATION_PERMISSIONS } from '/src/features/installation/permissions';
import { TEAM_AND_SHARING_PERMISSIONS } from '/src/features/team-and-sharing/permissions';
import { WHATS_NEW_PERMISSIONS } from '/src/features/whats-new/permissions';
import { FUNNEL_PERMISSIONS } from '/src/features/funnel/permissions';
import { SSO_PERMISSIONS } from '/src/features/sso/permissions';
import { HOME_PERMISSIONS } from '../features/home/permissions';
import { WEB_ANALYTICS_PERMISSIONS } from '../features/web-analytics/permissions';

const RESOLVERS = {
  ...HOME_PERMISSIONS,
  ...LIVE_ACTIVITY_PERMISSIONS,
  ...SNAPSHOTS_PERMISSIONS,
  ...RECORDINGS_PERMISSIONS,
  ...GOALS_PERMISSIONS,
  ...AB_TESTING_PERMISSIONS,
  ...TRAFFIC_ANALYSIS_PERMISSIONS,
  ...ERRORS_PERMISSIONS,
  ...ADDONS_PERMISSIONS,
  ...WHATS_NEW_PERMISSIONS,
  ...OPTIONS_PERMISSIONS,
  ...INSTALLATION_PERMISSIONS,
  ...TEAM_AND_SHARING_PERMISSIONS,
  ...AUDIT_LOG_PERMISSIONS,
  ...ACCOUNT_PERMISSIONS,
  ...COMMENTING_PERMISSIONS,
  ...FUNNEL_PERMISSIONS,
  ...SSO_PERMISSIONS,
  ...WEB_ANALYTICS_PERMISSIONS,
  Help: ({ capabilities }) => ({
    navigate: capabilities?.help.permissions.nav,
  }),
  EditorDashboard: ({ currentUser }) => {
    return {
      navigate: currentUser.features.includes('editor_feature') && !currentUser.features.includes('hide-editor'),
    };
  },
};

export function usePermissions() {
  const { currentUser, currentAccount, sessionInfo, features, capabilities, subscription, isSharing, sharedResource } =
    useAuthContext();

  function permissionsOf({ __typename, ...entity }) {
    const { subscriptions, ...userWithoutAccount } = currentUser ?? {};

    if (!__typename) throw new Error('usePermissions.of only work on GQL objects with __typenames defined');

    const evalFunction = RESOLVERS?.[__typename] ?? null;

    if (!evalFunction) {
      throw new Error(`__typename: ${__typename} has not been registered`);
    }

    return evalFunction({
      currentUser: currentUser ? userWithoutAccount : undefined,
      features,
      capabilities,
      subscription,
      currentAccount,
      sessionInfo,
      entity,
      subscriptions,
      isSharing,
      sharedResource,
    });
  }

  function permissionsFor(feature) {
    const { subscriptions, ...userWithoutAccount } = currentUser ?? {};

    const evalFunction = RESOLVERS?.[feature] ?? null;

    if (!evalFunction) {
      throw new Error(`Permissions: ${feature} has not been registered`);
    }

    return evalFunction({
      currentUser: currentUser ? userWithoutAccount : undefined,
      features,
      capabilities,
      subscription,
      currentAccount,
      sessionInfo: sessionInfo,
      subscriptions,
      isSharing,
      sharedResource,
    });
  }

  function permissionsCan(action, entity, criteria = PERMISSION_CRITERIAS.EVERY) {
    if (criteria !== PERMISSION_CRITERIAS.EVERY && criteria !== PERMISSION_CRITERIAS.SOME) {
      throw new Error('wrong criteria parameter for permission checking');
    }

    if (!entity) {
      return { allowed: false, reason: CAPABILITY_REASONS.PERMISSION_UNDEFINED };
    }

    if (Array.isArray(entity)) {
      const entities = entity.map((obj) => permissionsCan(action, obj));
      if (criteria === PERMISSION_CRITERIAS.SOME) {
        return entities.some((result) => result.allowed === true) ? { allowed: true } : { allowed: false };
      }
      return entities.every((result) => result.allowed === true) ? { allowed: true } : { allowed: false };
    } else {
      let objectPermissions = {};
      if (typeof entity === 'object' && typeof entity.__typename !== 'undefined') {
        objectPermissions = permissionsOf(entity);
      } else if (typeof entity === 'string') {
        objectPermissions = permissionsFor(entity);
      }
      if (typeof (objectPermissions?.[action] ?? false) === 'function') {
        return objectPermissions?.[action]();
      }
      return objectPermissions?.[action] ?? { allowed: false, reason: CAPABILITY_REASONS.PERMISSION_UNDEFINED };
    }
  }

  return {
    of: permissionsOf,
    can: permissionsCan,
  };
}
