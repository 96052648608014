import classNames from 'classnames';

export function SiteSettingsColumnSection({ placement = 'full', children }) {
  return (
    <div
      className={classNames('relative flex flex-col justify-center normal-case', {
        'mr-1.25 w-2/6': placement === 'left',
        'ml-1.25 w-4/6': placement === 'right',
        'h-full w-full': placement === 'full',
      })}
    >
      {children}
    </div>
  );
}

function SiteSettingsColumnSubtitle({ withToolTip = false, children }) {
  return (
    <span
      className={classNames('text-body-4 mb-0.5 leading-tight', {
        'self-start border-b border-dashed border-cadet-blue-500': withToolTip,
      })}
    >
      {children}
    </span>
  );
}

SiteSettingsColumnSection.Subtitle = SiteSettingsColumnSubtitle;
