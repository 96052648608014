import classNames from 'classnames';
import { SkeletonLine } from '@crazyegginc/hatch';

import { round } from '/src/utils/math';

import { ReactComponent as UpIcon } from '@crazyegginc/hatch/dist/images/icon-arrow.svg';

export function TrendMetricSection({ className, children }) {
  return (
    <div
      className={classNames(
        'p-5 flex w-full items-stretch justify-between gap-x-3 border-b border-mystic-500',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function TrendMetric({
  text,
  extraText,
  value,
  oldValue,
  loading,
  inverseColor = false,
  formatFn = (x) => Number(x).toLocaleString(),
}) {
  const change = round((value / oldValue - 1) * 100, 1);
  const isNegative = change < 0;

  return (
    <div className={classNames('flex w-1 grow flex-col justify-between h-[72px]')}>
      <div className="text-header-5 text-lynch-500">{text}</div>
      {loading ? (
        <>
          <SkeletonLine className="h-[30px] w-14 !rounded-md" />
          <SkeletonLine className="h-[10px] w-8 !rounded-md" />
        </>
      ) : (
        <>
          <div className="text-2xl text-black-pearl-500 ">{formatFn(value)}</div>
          {isNaN(change) ? (
            extraText ? null : (
              <span>&nbsp;</span>
            )
          ) : (
            <div
              className={classNames('flex items-center text-2xs font-semibold', {
                'text-lima-500': (!isNegative && !inverseColor) || (isNegative && inverseColor),
                'text-carnation-500': (isNegative && !inverseColor) || (!isNegative && inverseColor),
              })}
            >
              <UpIcon
                className={classNames('h-2.5 w-2.5 fill-current', {
                  'rotate-90': isNegative,
                  '-rotate-90': !isNegative,
                })}
              />
              <span className="ml-1">{isNegative ? change : `+${change}`}%</span>
            </div>
          )}
          {extraText ? extraText : null}
        </>
      )}
    </div>
  );
}
