import { Link } from 'react-router-dom';
//import { useQuery } from '@tanstack/react-query';

import { Section } from '../Section';
import { TrendMetric, TrendMetricSection } from '../TrendMetric';
import { TableHeader, TableRow, TableSkeleton } from '../TableSection';

import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions /*useSelectedSite*/ } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';
//import { goalSimpleListQuery } from '/src/features/goals/queries';
import { NoGoalsWall } from '/src/features/goals/paywalls/NoGoalsWall';

import { ReactComponent as GoalsIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';

export function GoalsSection({ data, loading }) {
  return (
    <Section className="col-span-2">
      <Section.Header icon={GoalsIcon} color="text-lima-500" to="/goals">
        <span>Goal Activity & Conversions</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.GOALS);

  if (!canViewDashboard) {
    return (
      <div className="p-5">
        <DashboardPaywall feature={FEATURES.GOALS} reason={reason} mini={true} />
      </div>
    );
  }

  return <GoalContent data={data} loading={loading} />;
}

function GoalContent({ data, loading }) {
  //const { selectedSite } = useSelectedSite();

  /*const { data: goalsData, isLoading } = useQuery({
    ...goalSimpleListQuery({ siteId: selectedSite?.id }),
    enabled: Boolean(selectedSite?.id),
  });*/

  //const goals = goalsData?.goalSimpleList;

  if (!loading && data?.goals.active.current === 0) {
    return (
      <div className="p-5">
        <NoGoalsWall mini={true} />
      </div>
    );
  }

  return (
    <>
      <TrendMetricSection>
        <TrendMetric
          text="Active"
          value={data?.goals.active.current}
          oldValue={data?.goals.active.previous}
          loading={loading}
        />
        <TrendMetric
          text="Had conversions"
          value={data?.goals.withConversions.current}
          oldValue={data?.goals.withConversions.previous}
          loading={loading}
        />
        <TrendMetric
          text="Total conversions"
          value={data?.goals.totalConversions.current}
          oldValue={data?.goals.totalConversions.previous}
          loading={loading}
        />
      </TrendMetricSection>
      <div>
        <TableHeader>
          <div>Most converted Goals</div>
          <div>Conversions</div>
        </TableHeader>
        {loading ? (
          <TableSkeleton />
        ) : (
          <>
            {data?.goals.topConversions.map((g, i) => (
              <TableRow key={g.id} index={i + 1}>
                <div key={g.id} className="grow truncate">
                  <Link className="text-link" to={`/goals/${g.id}`}>
                    {g.name}
                  </Link>
                </div>
                <div className="ml-4 flex w-12 shrink-0 items-center justify-end text-right">
                  {g.conversions.toLocaleString()}
                </div>
              </TableRow>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to="/goals" />
    </>
  );
}
