import React from 'react';
import { format, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { capitalize } from '@crazyegginc/hatch';

import { AppConfig } from '../../AppConfig';
import { getSessionQueryParameters } from '/src/utils/url';
import { IconWithTooltip } from './IconWithTooltip';

export function RenderInfos({ infos, consoleErrors = null, originalUrl, finalUrl }) {
  if ((!consoleErrors || consoleErrors.length === 0) && (!infos || infos.length === 0)) {
    return null;
  }

  return (
    <>
      <div className="h-[30px]" />
      {infos &&
        infos
          .filter((info) => info.name === 'PAGE_REDIRECTS')
          .map((info, i) => (
            <div className="text-body-2 relative min-h-[30px] py-1" key={`PageRedirects-${i}`}>
              <IconWithTooltip type="info" />
              <div>
                <strong className="text-body-1">Page Redirect Detected -</strong>&nbsp;The webpage redirected to{' '}
                <strong className="text-body-1">{info.redirect}</strong>, so the install check was run on{' '}
                <strong className="text-body-1">{info.redirect}</strong>. This might be because{' '}
                <strong className="text-body-1">{originalUrl}</strong> is behind a login.
              </div>
            </div>
          ))}
      {infos &&
        infos
          .filter((info) => info.name === 'HAS_SCHEDULED_SNAPSHOT')
          .map((info, i) => (
            <div className="text-body-2 relative min-h-[30px] py-1" key={`HasScheduledSnapshot-${i}`}>
              <IconWithTooltip type="info" />
              <div>
                <strong className="text-body-1">Snapshots -</strong>&nbsp;This page has a Snapshot scheduled to start on{' '}
                <strong className="text-body-1">{format(fromUnixTime(info.startsAt), 'MMMM dd, yyyy')}</strong>.
              </div>
            </div>
          ))}
      {infos && infos.find((info) => info.name === 'NO_RUNNING_SNAPSHOTS') && (
        <div className="text-body-2 relative min-h-[30px] py-1">
          <IconWithTooltip type="info" />
          <div>
            <strong className="text-body-1">Snapshots -</strong>&nbsp;There are currently no Snapshots running on{' '}
            <strong className="text-body-1">{finalUrl}</strong>
          </div>
        </div>
      )}
      {infos &&
        infos
          .filter((info) => info.name === 'RECORDINGS_DISABLED')
          .map((info, i) => (
            <div className="text-body-2 relative min-h-[30px] py-1" key={`RecordingsDisabled-${i}`}>
              <IconWithTooltip type="info" />
              <div>
                <strong className="text-body-1">Recordings -</strong>&nbsp;This URL is currently not set under page
                targeting or the site needs to be enabled.{' '}
                <Link target="_blank" rel="noopener noreferrer" className="text-link" to={`/options/site-settings`}>
                  Click here
                </Link>{' '}
                to adjust the settings.
              </div>
            </div>
          ))}
      {infos &&
        infos
          .filter((info) => info.name === 'DATA_BEING_TRACKED_TO')
          .map((info, i) => (
            <div className="text-body-2 relative min-h-[30px] py-1" key={`DataBeingTrackedTo-${i}`}>
              <IconWithTooltip type="info" />
              <div>
                <strong className="text-body-1">Snapshots -</strong>&nbsp;Data from{' '}
                <strong className="text-body-1">{finalUrl}</strong> is currently being tracked to{' '}
                {info.snapshotHasData ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-link"
                    href={`${AppConfig.legacyCoreBaseURL()}/snapshots/${
                      info.snapshotId
                    }?${getSessionQueryParameters()}`}
                  >
                    {info.snapshotName}
                  </a>
                ) : (
                  <Link target="_blank" rel="noopener noreferrer" className="text-link" to="/snapshots">
                    {info.snapshotName}
                  </Link>
                )}{' '}
                on {capitalize(info.snapshotDevice.toLowerCase())}
              </div>
            </div>
          ))}
    </>
  );
}
