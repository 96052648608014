import { useRef, useState, useCallback, useMemo } from 'react';
import { fromUnixTime, getUnixTime, startOfDay, endOfDay, subYears, format } from 'date-fns';
//import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { DateRangePicker, ReactTable, Select } from '@crazyegginc/hatch';

import { DashboardPage } from '/src/components/Page';
import { DashboardPaywall } from '../../_global/paywalls/DashboardPaywall';
import { SEO } from '/src/components/SEO';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker/index';

import { useSite, useQueryParams, usePermissions } from '/src/hooks';
import { getFeatureName } from '/src/features/_global/utils';
import { getInitialDate, getConvertedDateRange } from '/src/utils/date';
import { camelToSnake, snakeToCamel } from '/src/utils/string';
import { FEATURES, SORT_ORDER_TYPES } from '/src/features/_global/constants';

import { DEFAULT_DATE_RANGE, DIMENSIONS } from '../constants';
import { webAnalyticsQuery } from '/src/features/web-analytics/queries';

// eslint-disable-next-line no-restricted-syntax
export default function WebAnalysisDashboard() {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.WEB_ANALYTICS);

  if (!canViewDashboard) {
    return <DashboardPaywall reason={reason} feature={FEATURES.HOME} />;
  }

  return (
    <DashboardPage>
      <SEO title="Home" />
      <DashHeader titleComponent={getFeatureName(FEATURES.WEB_ANALYTICS)} />

      <WebAnalysisContent />
    </DashboardPage>
  );
}

function WebAnalysisContent() {
  const { sites, loadingSites, selectedSite, selectSite } = useSite();
  const { set: queryParamsSet, get: queryParamsGet } = useQueryParams();
  const [dimension, setDimension] = useState('DATE');
  const [field, setField] = useState('DATE');
  const [sort, setSort] = useState(SORT_ORDER_TYPES.DESC);
  const [dateRange, setDateRange] = useState(() => getInitialDate(queryParamsGet('date')) ?? DEFAULT_DATE_RANGE);
  const chooseDateRange = useCallback(
    (value) => {
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({
            start_date: getUnixTime(value.start_date),
            end_date: getUnixTime(value.end_date),
          }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
      setDateRange(value);
    },
    [queryParamsSet, setDateRange],
  );

  const convertedDateRange = getConvertedDateRange(dateRange);

  const tableRef = useRef(null);
  const columns = useMemo(
    () => [
      {
        header: 'Clicks',
        accessorKey: 'clicks',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function Clicks({ row }) {
          return row.original.clicks;
        },
      },
      {
        header: 'Visits',
        accessorKey: 'visits',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function Visits({ row }) {
          return row.original.visits;
        },
      },
      {
        header: 'Conversions',
        accessorKey: 'conversions',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function Conversions({ row }) {
          return row.original.conversions;
        },
      },
      {
        header: 'Unique Visits',
        accessorKey: 'uniqueVisits',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function UniqueVisits({ row }) {
          return row.original.uniqueVisits;
        },
      },
      {
        header: 'Page Views',
        accessorKey: 'pageViews',
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function PageViews({ row }) {
          return row.original.pageViews;
        },
      },
      {
        header: dimension.toLowerCase(),
        accessorKey: dimension.toLowerCase(),
        size: 40,
        meta: {
          align: 'center',
          textAlign: 'center',
        },
        cell: function Date({ row }) {
          return row.original[dimension.toLowerCase()];
        },
      },
    ],
    [dimension],
  );

  const queryDateFormat = (date) => format(fromUnixTime(date), 'yyyy-MM-dd');

  const { data, isLoading } = useQuery({
    ...webAnalyticsQuery({
      metrics: ['CLICKS', 'VISITS', 'CONVERSIONS', 'PAGE_VIEWS', 'UNIQUE_VISITS'],
      filters: {
        date: [queryDateFormat(convertedDateRange.startDate), queryDateFormat(convertedDateRange.endDate)],
        site_id: selectedSite?.id,
      },
      dimension: dimension,
      order: field,
      direction: sort,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const tableSortData = useMemo(() => {
    return {
      id: snakeToCamel(field),
      desc: sort === SORT_ORDER_TYPES.DESC,
    };
  }, [field, sort]);

  const onFetchData = useCallback(
    ({ sorting }) => {
      if (sorting) {
        const nextField = camelToSnake(sorting[0].id).toUpperCase();
        setField(nextField);
        const nextSort = sorting[0].desc ? SORT_ORDER_TYPES.DESC : SORT_ORDER_TYPES.ASC;
        setSort(nextSort);
      }
    },
    [setField, setSort],
  );

  return (
    <>
      <SitePickerBar
        sites={sites}
        loading={loadingSites}
        selectedSite={selectedSite}
        selectSite={selectSite}
        enableAllSites={true}
      />
      <div className="flex flex-col p-10">
        <div className="mb-[30px] flex justify-between">
          <Select
            options={DIMENSIONS.map((x) => ({ value: x }))}
            value={dimension}
            label="Grouping"
            onChange={(value) => {
              setDimension(value);
              setField(value);
            }}
            labelClassName="font-semibold"
          />
          <DateRangePicker
            lowerBoundary={startOfDay(subYears(new Date(), 2))}
            upperBoundary={endOfDay(new Date())}
            startDate={dateRange?.start_date}
            endDate={dateRange?.end_date}
            special={dateRange?.special}
            setDateRange={chooseDateRange}
            setCommonDateRange={chooseDateRange}
            showCommonRanges={true}
            size="lg"
          />
        </div>
        <ReactTable
          ref={{ tableRef }}
          fetching={isLoading}
          rowPadding={true}
          columns={columns}
          data={data?.report || []}
          rowHeight={50}
          onFetchData={onFetchData}
          enableSorting={true}
          sorting={tableSortData}
        />
      </div>
    </>
  );
}
