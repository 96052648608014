import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { useAuthContext, usePermissions } from '/src/hooks';
import { Paywall } from '/src/components/Paywall';
import { FEATURES } from '/src/features/_global/constants';

import RecordingsPNG from '@crazyegginc/hatch/dist/images/illustration-recordings-2.png';
import RecordingsWebP from '@crazyegginc/hatch/dist/images/illustration-recordings-2.webp';

export function EnableRecordingsWall({ mini }) {
  const { currentAccount } = useAuthContext();

  const permissions = usePermissions();
  const canManageSettings = permissions.can('settings', FEATURES.RECORDINGS).allowed;

  return (
    <Paywall>
      <Paywall.Image
        webp={RecordingsWebP}
        png={RecordingsPNG}
        width={mini ? '80px' : '176px'}
        className={mini ? '!mb-3 !mt-2' : ''}
      />
      <Paywall.Title mini={mini}>Let&#39;s get recording!</Paywall.Title>

      <Paywall.Body>
        To start gathering visitor recordings and learning more about your vistor&#39;s behavior, you&#39;ll need to
        enable the feature on your website.
      </Paywall.Body>

      {canManageSettings ? (
        <Button component={Link} to={`/options/site-settings`}>
          Enable recordings
        </Button>
      ) : (
        <Button component="a" href={`mailto:${currentAccount.ownerEmail}`}>
          Contact account owner
        </Button>
      )}
    </Paywall>
  );
}

EnableRecordingsWall.displayName = 'EnableRecordingsWall';
