import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Section } from '../Section';
import { TrendMetric, TrendMetricSection } from '../TrendMetric';
import { TableHeader, TableRow, TableSkeleton } from '../TableSection';

import { NoAbTestsWall } from '/src/features/ab-testing/components/paywalls/NoAbTestsWall';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';

import { usePermissions, useSelectedSite } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

import { abTestListQuery } from '/src/features/ab-testing/queries';
import { RateDelta } from '/src/features/ab-testing/utils';
import { AB_TEST_STATUSES } from '/src/features/ab-testing/constants';

import { ReactComponent as AbTestingIcon } from '@crazyegginc/hatch/dist/images/icon-ab-test-outline.svg';

export function ABTestingSection({ data, loading }) {
  return (
    <Section className="col-span-1">
      <Section.Header icon={AbTestingIcon} color="text-cornflower-500" to="/ab-tests">
        <span>A/B Testing</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.AB_TESTING);

  if (!canViewDashboard) {
    return (
      <div className="p-5">
        <DashboardPaywall feature={FEATURES.AB_TESTING} reason={reason} mini={true} />
      </div>
    );
  }

  return <ABTestContent data={data} loading={loading} />;
}

function ABTestContent({ data, loading }) {
  const { selectedSite } = useSelectedSite();

  const { data: abTestList, isLoading } = useInfiniteQuery({
    ...abTestListQuery({
      site: selectedSite?.id,
      status: undefined,
      limit: 10,
    }),
    enabled: Boolean(selectedSite?.id),
  });
  const abTests = useMemo(
    () =>
      (abTestList?.pages.reduce((acc, page) => [...acc, ...(page.abTestList?.list ?? [])], []) ?? [])
        .filter((t) => t.status !== AB_TEST_STATUSES.DRAFT)
        .slice(0, 3),
    [abTestList],
  );

  if (!loading && data?.abTests.active === 0) {
    return (
      <div className="p-5">
        <NoAbTestsWall mini={true} />
      </div>
    );
  }

  return (
    <>
      <TrendMetricSection>
        <TrendMetric text="Active" value={data?.abTests.active} loading={loading} />
        <TrendMetric text="Visitors" value={data?.abTests.totalVisitors} loading={loading} />
        <TrendMetric text="Conversions" value={data?.abTests.totalConversions} loading={loading} />
      </TrendMetricSection>
      <div>
        <TableHeader>Most recent tests</TableHeader>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            {abTests.map((t, i) => (
              <TableRow key={t.id} index={i + 1}>
                <div className="grow truncate">
                  <Link className="text-link" to={`/ab-tests/${t.id}`}>
                    {t.name}
                  </Link>
                </div>
                {t.bestVariant ? (
                  <>
                    <div className="grow truncate shrink-0 w-[70px] ml-2 text-right">{t.bestVariant.variantName}</div>
                    <div className="ml-3 flex w-10 shrink-0 items-center justify-end text-right">
                      {t.bestVariant.type.toUpperCase() === 'CONTROL' ? null : (
                        <RateDelta value={t.bestVariant.results.improvementFromControl} />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="w-[110px] ml-2 text-right shrink-0">Not enough data</div>
                )}
              </TableRow>
            ))}
          </>
        )}
      </div>
      <Section.SeeMore to="/ab-tests" />
    </>
  );
}
