//import { useMemo } from 'react';
import { Link } from 'react-router-dom';
//import { useInfiniteQuery } from '@tanstack/react-query';

import { FEATURES } from '/src/features/_global/constants';
import { Section } from '../Section';
import { TrendMetric, TrendMetricSection } from '../TrendMetric';
import { TableHeader, TableRow, TableSkeleton } from '../TableSection';

import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions /*useSelectedSite */ } from '/src/hooks';
import { NoCTAsWall } from '/src/features/addons/paywalls/NoCTAsWall';
//import { addonListQuery } from '/src/features/addons/queries';
//import { ADDON_TYPES } from '/src/features/addons/constants';

import { ReactComponent as CTAsIcon } from '@crazyegginc/hatch/dist/images/icon-click-outline.svg';

export function CTAsSection({ data, loading }) {
  return (
    <Section className="col-span-1" to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }}>
      <Section.Header icon={CTAsIcon} color="text-california-500">
        <span>CTA Activity</span>
      </Section.Header>
      <Section.Body>
        <Content data={data} loading={loading} />
      </Section.Body>
    </Section>
  );
}

function Content({ data, loading }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.CTAS);

  if (!canViewDashboard) {
    return (
      <div className="p-5">
        <DashboardPaywall feature={FEATURES.CTAS} reason={reason} mini={true} />
      </div>
    );
  }

  return <CTAContent data={data} loading={loading} />;
}

function CTAContent({ data, loading }) {
  //const { selectedSite } = useSelectedSite();

  /*const { data: addonsData, isLoading } = useInfiniteQuery({
    ...addonListQuery({
      siteId: selectedSite?.id,
      filter: {
        limit: 1,
        query: undefined,
        type: ADDON_TYPES.CTA,
      },
    }),
    enabled: Boolean(selectedSite?.id),
  });*/

  /*const ctas = useMemo(
    () => addonsData?.pages.reduce((acc, page) => [...acc, ...(page.addonList?.list ?? [])], []) ?? [],
    [addonsData],
  );*/

  if (!loading && data?.addons.activeCtas === 0) {
    return (
      <div className="p-5">
        <NoCTAsWall mini={true} />
      </div>
    );
  }

  return (
    <>
      <TrendMetricSection>
        <TrendMetric text="Active" value={data?.addons.activeCtas} loading={loading} />
        <TrendMetric
          text="Clicks"
          value={data?.addons.totalClicks.current}
          oldValue={data?.addons.totalClicks.previous}
          loading={loading}
        />
      </TrendMetricSection>

      {!loading && data?.addons.topCtas.length === 0 ? null : (
        <div>
          <TableHeader>
            <div>Top 3 CTAs</div>
            <div>Clicks</div>
          </TableHeader>
          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {data?.addons.topCtas.map((c, i) => (
                <TableRow key={c.id} index={i + 1}>
                  <div key={c.id} className="grow truncate">
                    <Link className="text-link" to={`/addons/${c.id}`}>
                      {c.name}
                    </Link>
                  </div>
                  <div className="ml-4 flex w-12 shrink-0 items-center justify-end text-right">
                    {c.clicks.toLocaleString()}
                  </div>
                </TableRow>
              ))}
            </>
          )}
        </div>
      )}
      <Section.SeeMore to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }} />
    </>
  );
}
