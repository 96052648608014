import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Tab } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { startOfDay, endOfDay, getUnixTime, fromUnixTime } from 'date-fns';
import { Tooltip as HatchTooltip, IconButton, DateRangePicker } from '@crazyegginc/hatch';

import { useAuthContext, useSite, useQueryParams, useSpecificCompatibilityCheck } from '/src/hooks';
import { VisitorIdProvider } from '/src/features/visitor-panel/visitor-id-context';
import { VisitorPanel } from '/src/features/visitor-panel/components/VisitorPanel';
import { SurveyResponseFilterProvider } from '/src/features/addons/survey-response-filter-context';
import { copyToClipboard } from '/src/utils';
import { getSurveyQueryParams } from '/src/utils/url';
import { getInitialDate, getConvertedDateRange } from '/src/utils/date';

import { FEATURES, SPECIAL_DATE_RANGES } from '/src/features/_global/constants';
import { SINGLE_SITE_QUERY } from '/src/features/_global/queries';
import { SITES_FOR_ADDONS } from '/src/features/addons/queries';

import { DashboardPage } from '/src/components/Page';
import { DashHeader } from '/src/components/headers/DashHeader';
import { SitePickerBar } from '/src/components/site-picker/index';
import { Breadcrumb, BreadcrumbItem } from '/src/components/Breadcrumb';
import { AddonNameTitle } from '../../common/results/AddonNameTitle';

import { DoughnutLabel } from '/src/chartjs-plugins/DoughnutLabel';
import { AddonActiveToggle } from '../../common/AddonActiveToggle';
import { PopoverActions } from '../../common/actions';
import { BackToDashboardButton } from '../../common/BackToDashboardButton';
import { SummaryTab } from '/src/features/addons/surveys/results/summary/SummaryTab';
import { AllResponsesTab } from '/src/features/addons/surveys/results/allResponses/AllResponsesTab';
import { IndividualTab } from '/src/features/addons/surveys/results/individual/IndividualTab';
import { getExternalSurveyLink } from '/src/features/addons/surveys/common-functions';

import { NotificationsButton } from '/src/features/notifications/components/NotificationsButton';
import { NOTIFICATION_RESOURCE_TYPES } from '/src/features/notifications/constants';

import { gettingStartedFeatureKeys } from '/src/features/getting-started/queries';

import { ReactComponent as LinkIcon } from '@crazyegginc/hatch/dist/images/icon-link-outline.svg';
import { ReactComponent as TickIcon } from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg';

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutLabel);

const compatibilityFeatures = ['AI'];

export function SurveyResults({ survey, refetchDetails }) {
  const navigate = useNavigate();
  const { isSharing, sharedResource } = useAuthContext();
  const queryClient = useQueryClient();

  const { sites, selectSite } = useSite({
    sitesQuery: isSharing ? SINGLE_SITE_QUERY : SITES_FOR_ADDONS,
    dontSelectSite: true,
    sitesQueryProps: isSharing ? { variables: { id: sharedResource.resource.siteId } } : undefined,
  });

  useSpecificCompatibilityCheck({
    features: compatibilityFeatures,
    enabled: true,
  });

  const surveySite = sites.find((site) => site.id === survey?.siteId);

  useEffect(() => {
    selectSite(surveySite);
  }, [surveySite, selectSite]);

  useEffect(() => {
    queryClient.invalidateQueries({ ...gettingStartedFeatureKeys.gettingStarted });
  }, [queryClient]);

  return (
    <DashboardPage>
      <DashHeader
        titleComponent={
          <Breadcrumb>
            {!isSharing && (
              <BreadcrumbItem
                active={false}
                to={{
                  pathname: `/addons`,
                  search: `type=${FEATURES.SURVEYS}${getSurveyQueryParams() ? `&${getSurveyQueryParams()}` : ''}`,
                }}
              >
                Surveys
              </BreadcrumbItem>
            )}
            <BreadcrumbItem active={true}>
              <AddonNameTitle fetching={false} addon={survey} />
            </BreadcrumbItem>
          </Breadcrumb>
        }
        actionButton={
          <div className="flex shrink-0 items-center space-x-2.5">
            {!isSharing && <BackToDashboardButton type={FEATURES.SURVEYS} />}
            <PopoverActions
              addon={survey}
              onDelete={() => {
                navigate(
                  { pathname: '/addons', search: getSurveyQueryParams({ type: FEATURES.SURVEYS }) },
                  { replace: true },
                );
              }}
            />
            {!isSharing ? <AddonActiveToggle addon={survey} showLoadingState={false} /> : null}
          </div>
        }
      />

      <SitePickerBar
        sites={surveySite ? [surveySite] : []}
        selectedSite={surveySite}
        loading={false}
        pageDisplayName="Survey Results"
      />

      <div className="flex flex-col p-10">
        <SurveysResultsContent survey={survey} refetchDetails={refetchDetails} />
      </div>
    </DashboardPage>
  );
}

const tabs = ['Summary', 'All responses', 'Individual responses'];
function SurveysResultsContent({ survey, refetchDetails }) {
  const { set: queryParamsSet, get: queryParamsGet } = useQueryParams();
  const initialTab = queryParamsGet('tab') ?? 0;
  const defaultDateRange = useMemo(
    () =>
      survey
        ? {
            start_date: startOfDay(fromUnixTime(survey.publishedAt)),
            end_date: endOfDay(new Date()),
          }
        : {
            special: SPECIAL_DATE_RANGES.LAST_30_DAYS,
          },
    [survey],
  );
  const [dateRange, setDateRange] = useState(() => getInitialDate(queryParamsGet('date')) ?? defaultDateRange);

  const chooseDateRange = useCallback(
    (value) => {
      if (value.start_date) {
        queryParamsSet(
          'date',
          JSON.stringify({
            start_date: getUnixTime(value.start_date),
            end_date: getUnixTime(value.end_date),
          }),
        );
      } else {
        queryParamsSet('date', JSON.stringify(value));
      }
      setDateRange(value);
    },
    [queryParamsSet, setDateRange],
  );

  const resetDateRange = useCallback(() => chooseDateRange(defaultDateRange), [chooseDateRange, defaultDateRange]);

  if (!survey) return null;

  const convertedDateRange = getConvertedDateRange(dateRange);

  return (
    <SurveyResponseFilterProvider>
      <VisitorIdProvider>
        <VisitorPanel />
        {survey.responseCount > 0 ? (
          <Tab.Group
            defaultIndex={initialTab}
            onChange={(index) => {
              queryParamsSet('tab', index);
            }}
          >
            <div className="mb-[30px] flex justify-between">
              <div className="flex w-full items-end">
                <Tab.List className="mr-5 flex border-b border-mystic-500">
                  {tabs.map((header) => (
                    <Tab
                      key={header}
                      className={({ selected }) =>
                        classNames(
                          'text-header-4 border-b-2 px-5 pb-3 text-dodger-blue-500 focus:outline-none focus-visible:outline-black',
                          'flex items-center',
                          {
                            'border-dodger-blue-500': selected,
                            'border-transparent': !selected,
                          },
                        )
                      }
                    >
                      {header}
                    </Tab>
                  ))}
                </Tab.List>
                <SurveyLink survey={survey} />
              </div>
              <div className="flex space-x-2.5">
                <NotificationsButton
                  resourceType={NOTIFICATION_RESOURCE_TYPES.SURVEY}
                  resourceId={survey?.id}
                  size="lg"
                />
                <DateRangePicker
                  lowerBoundary={startOfDay(fromUnixTime(survey.publishedAt))}
                  upperBoundary={endOfDay(new Date())}
                  startDate={dateRange?.start_date}
                  endDate={dateRange?.end_date}
                  special={dateRange?.special}
                  setDateRange={chooseDateRange}
                  setCommonDateRange={chooseDateRange}
                  showCommonRanges={true}
                  size="lg"
                  defaultRangeText="all responses"
                  defaultDateRange={defaultDateRange}
                />
              </div>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                <SummaryTab
                  survey={survey}
                  refetchDetails={refetchDetails}
                  convertedDateRange={convertedDateRange}
                  resetDateRange={resetDateRange}
                />
              </Tab.Panel>
              <Tab.Panel>
                <AllResponsesTab
                  survey={survey}
                  convertedDateRange={convertedDateRange}
                  resetDateRange={resetDateRange}
                />
              </Tab.Panel>
              <Tab.Panel>
                <IndividualTab
                  survey={survey}
                  convertedDateRange={convertedDateRange}
                  resetDateRange={resetDateRange}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <>
            <div className="mb-4 flex flex-1 items-center justify-between">
              <SurveyLink survey={survey} minimizeOnSmallScreens={false} />
              <NotificationsButton resourceType={NOTIFICATION_RESOURCE_TYPES.SURVEY} resourceId={survey?.id} />
            </div>
            <SummaryTab
              survey={survey}
              refetchDetails={refetchDetails}
              convertedDateRange={convertedDateRange}
              resetDateRange={resetDateRange}
            />
          </>
        )}
      </VisitorIdProvider>
    </SurveyResponseFilterProvider>
  );
}

function SurveyLink({ survey, minimizeOnSmallScreens = true }) {
  const { currentAccount } = useAuthContext();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const url = getExternalSurveyLink(survey.id, currentAccount?.id);
  const copyUrl = () => {
    copyToClipboard(url);
    setCopied(true);
  };

  if (!url) {
    return null;
  }

  return (
    <div className="text-header-4 flex h-10 min-w-0 max-w-sm items-center rounded bg-mystic-500/50 px-3">
      <div className="shrink-0">
        <HatchTooltip tooltipContent={<div className="w-16">{copied ? 'Copied!' : 'Copy link'}</div>}>
          <IconButton
            icon={
              copied ? (
                <TickIcon className="h-4 w-4 fill-current text-lima-500" />
              ) : (
                <LinkIcon className="h-4 w-4 fill-current" />
              )
            }
            className="text-cadet-blue-500 hover:text-dodger-blue-500 mr-1"
            onClick={copyUrl}
          />
        </HatchTooltip>
      </div>
      <span
        className={classNames('mr-1.25 shrink-0 text-lynch-500', {
          'hidden 2xl:block': minimizeOnSmallScreens,
        })}
      >
        Survey link
      </span>
      <a
        href={url}
        className={classNames('text-link truncate', {
          'hidden 2xl:block': minimizeOnSmallScreens,
        })}
        target="_blank"
        rel="noreferrer"
      >
        {url}
      </a>
      <a
        href={url}
        className={classNames('shrink-0 text-link text-base', {
          '2xl:hidden': minimizeOnSmallScreens,
          ' hidden': !minimizeOnSmallScreens,
        })}
      >
        Survey link
      </a>
    </div>
  );
}
