import classNames from 'classnames';
import { SkeletonLine } from '@crazyegginc/hatch';

export function TableHeader({ children, className }) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between h-[55px] shrink-0 text-2xs font-semibold px-5',
        'border-b border-mystic-500 border-dashed',
        'uppercase tracking-wider leading-none',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function TableRow({ index, children, className }) {
  return (
    <div className={classNames('shrink-0 text-body-2 px-5 truncate group', 'bg-white even:bg-off-white-500')}>
      <div
        className={classNames(
          'relative flex items-center justify-between h-[55px]',
          'border-b border-mystic-500 border-dashed group-last:border-b-0',
          className,
          {
            'pl-6': index,
          },
        )}
      >
        {index ? <div className="absolute left-0">{index}</div> : null}
        {children}
      </div>
    </div>
  );
}

export function TableSkeleton() {
  return (
    <>
      <TableRow index={1}>
        <SkeletonLine className="!w-4/5" />
      </TableRow>
      <TableRow index={2}>
        <SkeletonLine className="!w-3/5" />
      </TableRow>
      <TableRow index={3}>
        <SkeletonLine className="!w-2/3" />
      </TableRow>
    </>
  );
}
