import { useRef, useMemo, useCallback } from 'react';
import { Spinner } from '@crazyegginc/hatch';

import { useSubscription, useSelectedSite } from '/src/hooks';
import { LIVE_EVENTS_SUBSCRIPTION } from '/src/features/live-activity/subscriptions';
import { parseEvents } from '/src/features/live-activity/live-functions';
import { EventCard } from '/src/features/live-activity/components/EventCard';
import { FilterProvider } from '/src/features/live-activity/live-filter-context';
import { LIVE_EVENT_TYPES } from '/src/features/live-activity/constants';
import { LiveNotInstalled } from '/src/features/live-activity/components/paywalls/LiveNotInstalled';

import { Section } from '../Section';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

import { ReactComponent as LiveIcon } from '@crazyegginc/hatch/dist/images/icon-list-outline.svg';

const MAX_EVENT_NUMBER = 3;

export function LiveSection() {
  return (
    <Section className="col-span-3">
      <Section.Header icon={LiveIcon} color="text-pink-salmon-500" to="/live">
        <span>Live Activity</span>
      </Section.Header>
      <Content />
    </Section>
  );
}

function Content() {
  const { selectedSite } = useSelectedSite();
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.LIVE_ACTIVITY);

  if (!canViewDashboard) {
    return (
      <Section.Body>
        <DashboardPaywall feature={FEATURES.LIVE_ACTIVITY} reason={reason} mini={true} />
      </Section.Body>
    );
  }

  if (selectedSite && !selectedSite.installed) {
    return (
      <Section.Body>
        <LiveNotInstalled mini={true} />
      </Section.Body>
    );
  }

  return <LiveContent />;
}

function LiveContent() {
  const { selectedSite } = useSelectedSite();
  const displayedEvents = useRef([]);
  const visitEvents = useRef({});
  const identifyEvents = useRef({});
  const pageCounts = useRef({});

  const { isLoading, data } = useSubscription(
    {
      query: LIVE_EVENTS_SUBSCRIPTION,
      variables: useMemo(() => ({ siteId: selectedSite?.id }), [selectedSite?.id]),
      onNext: useCallback((acc = [], newEvents) => {
        if (!newEvents?.liveEvents) return acc;
        const res = [...newEvents.liveEvents.map((e) => ({ ...e, attributes: JSON.parse(e.attributes) })), ...acc];
        if (res.length > MAX_EVENT_NUMBER + 10) res.splice(MAX_EVENT_NUMBER + 10);
        return res;
      }, []),
    },
    {
      queryKey: useMemo(() => ['home-liveEvents'], []),
      enabled: Boolean(selectedSite?.id && selectedSite?.installed),
      staleTime: 0,
    },
  );

  const receivedEvents = useMemo(() => data ?? [], [data]);

  const { newVisitEvents, newIdentifyEvents, newDisplayedEvents, newPageCounts } = parseEvents(
    receivedEvents,
    visitEvents.current,
  );

  displayedEvents.current = newDisplayedEvents;
  visitEvents.current = Object.assign(visitEvents.current, newVisitEvents);
  identifyEvents.current = Object.assign(identifyEvents.current, newIdentifyEvents);
  pageCounts.current = newPageCounts;

  return (
    <div>
      {isLoading || !data ? (
        <Section.Body className="items-center !py-8">
          <div className="text-header-3 flex items-center">
            <Spinner className="mr-3 h-5 w-5 text-cadet-blue-500" />
            <span className="text-lynch-500">Loading live activity</span>
          </div>
        </Section.Body>
      ) : (
        <>
          <FilterProvider>
            <div className="relative pl-[62px] pt-5 pr-5">
              <div className="absolute left-[25px] top-10 h-[calc(100%-85px)] w-1 bg-mystic-500" />
              {displayedEvents.current
                .filter((e) => e.event !== LIVE_EVENT_TYPES.IDENTIFY)
                .slice(0, MAX_EVENT_NUMBER)
                .map((e) => (
                  <EventCard
                    key={e.eventId}
                    liveEvent={e}
                    visitEvents={visitEvents.current}
                    identifyEvents={identifyEvents.current}
                    pageCounts={pageCounts.current}
                    pauseOnHover={false}
                  />
                ))}
            </div>
          </FilterProvider>
          <Section.SeeMore to="/live" />
        </>
      )}
    </div>
  );
}
