import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { usePermissions, useSelectedSite } from '/src/hooks';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';
import { Section } from '../Section';
import { TrendMetric, TrendMetricSection } from '../TrendMetric';
import { TableHeader, TableRow, TableSkeleton } from '../TableSection';

import { trafficAnalysisQuery, trafficAnalysisUrlsQuery } from '/src/features/traffic-analysis/queries';
import { initialSelection, getEmptyText } from '/src/features/traffic-analysis/utils';
import { SelectedValues } from '/src/features/traffic-analysis/components/SelectedValues';
import { round } from '/src/utils/math';
import { msToHumanDuration } from '/src/utils/date';
import { FEATURES } from '/src/features/_global/constants';
import { DashboardPaywall } from '/src/features/_global/paywalls/DashboardPaywall';
import { EnableRecordingsWall } from '/src/features/recordings/components/dashboard/paywalls/EnableRecordingsWall';
import { TruncateWithTooltip } from '/src/components/TruncateWithTooltip';

import { ReactComponent as VisitorsIcon } from '@crazyegginc/hatch/dist/images/icon-people-outline.svg';

export function VisitorSection({ dateRange, loading, data }) {
  return (
    <Section className="col-span-2">
      <Section.Header icon={VisitorsIcon} color="text-dodger-blue-500" to="/traffic">
        <span>Traffic & Engagement</span>
      </Section.Header>
      <Section.Body>
        <Content dateRange={dateRange} loading={loading} data={data} />
      </Section.Body>
    </Section>
  );
}

function Content({ dateRange, loading, data }) {
  const permissions = usePermissions();
  const { allowed: canViewDashboard, reason } = permissions.can('view', FEATURES.TRAFFIC_ANALYSIS);

  if (!canViewDashboard) {
    <div className="p-5">
      <DashboardPaywall feature={FEATURES.TRAFFIC_ANALYSIS} reason={reason} mini={true} />
    </div>;
  }

  return <VisitorContent dateRange={dateRange} loading={loading} data={data} />;
}

function VisitorContent({ dateRange, loading, data }) {
  const { selectedSite } = useSelectedSite();

  if (selectedSite && !selectedSite.sessionRecording) {
    return (
      <div className="p-5">
        <EnableRecordingsWall mini={true} />
      </div>
    );
  }

  return (
    <>
      <TrendMetricSection>
        <TrendMetric
          text="Average unique visitors per day"
          value={data?.visitors.avgDailyUniqueVisitors.current}
          oldValue={data?.visitors.avgDailyUniqueVisitors.previous}
          loading={loading}
        />
        <TrendMetric
          text="Average visit duration"
          value={data?.visitors.avgVisitDuration.current}
          oldValue={data?.visitors.avgVisitDuration.previous}
          loading={loading}
          formatFn={(x) => msToHumanDuration(x * 1000)}
        />
        <TrendMetric
          text="Average pages per session"
          value={data?.visitors.avgPages.current}
          oldValue={data?.visitors.avgPages.previous}
          loading={loading}
        />
      </TrendMetricSection>
      <TopTraffic dateRange={dateRange} />
    </>
  );
}

function TopTraffic({ dateRange }) {
  const { selectedSite } = useSelectedSite();

  const { data, isLoading } = useQuery({
    ...trafficAnalysisUrlsQuery({
      siteId: selectedSite?.id,
      startAt: dateRange.start,
      endAt: dateRange.end,
      filters: [],
      order: {
        filterIndex: 0,
        sort: SORT_ORDER_TYPES.DESC,
      },
    }),
    enabled: Boolean(selectedSite?.id),
  });
  const total = data?.trafficAnalysisUrls[0]?.total;

  return (
    <div className="flex">
      <TopTrafficSources dateRange={dateRange} total={total} totalLoading={isLoading} />
      <TopLandingPages dateRange={dateRange} data={data} total={total} totalLoading={isLoading} />
    </div>
  );
}

function TopTrafficSources({ dateRange, total, totalLoading }) {
  const { selectedSite } = useSelectedSite();

  const { data, isLoading } = useQuery({
    ...trafficAnalysisQuery({
      siteId: selectedSite?.id,
      startAt: dateRange.start,
      endAt: dateRange.end,
      limit: 500,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const topSources = useMemo(() => {
    const regexp = new RegExp(selectedSite?.name);
    return (
      data?.trafficAnalysis
        ?.filter((x) => !regexp.test(x.referrerUrl))
        ?.map((x) => ({
          utmSource: x.utmSource ?? '',
          utmMedium: x.utmMedium ?? '',
          utmCampaign: x.utmCampaign ?? '',
          utmTerm: x.utmTerm ?? '',
          utmContent: x.utmContent ?? '',
          referrerUrl: x.referrerUrl ?? '',
          total: x.total,
        }))
        .slice(0, 3) ?? []
    );
  }, [data?.trafficAnalysis, selectedSite]);

  function createSelectionObj(row) {
    const params = initialSelection();
    const includeEmpty = Object.keys(params).every((key) => row[key] === '');
    Object.keys(params).forEach((key) => {
      if (row[key] !== '' || includeEmpty) {
        params[key].push({ value: row[key], label: includeEmpty ? getEmptyText(key) : null });
      }
    });
    return params;
  }

  return (
    <div className="w-1 grow border-r border-mystic-500">
      <TableHeader>Top traffic sources</TableHeader>
      {isLoading || totalLoading ? (
        <TableSkeleton />
      ) : (
        topSources.map((s, i) => (
          <TableRow key={JSON.stringify(s)} index={i + 1} className="!h-auto min-h-[55px] py-2">
            <div className="grow">
              <SelectedValues options={{}} selection={createSelectionObj(s)} checkFilteredOut={false} />
            </div>
            <div className="ml-4 flex w-14 shrink-0 items-center justify-end text-right">
              {round((s.total / total) * 100, 1).toLocaleString()}%
            </div>
          </TableRow>
        ))
      )}
      <Section.SeeMore to="/traffic" />
    </div>
  );
}

function TopLandingPages({ data, total, totalLoading }) {
  const { selectedSite } = useSelectedSite();

  const topLandingPages = useMemo(() => {
    return data?.trafficAnalysisUrls.slice(1).slice(0, 3);
  }, [data]);

  const regex = new RegExp(`^${selectedSite?.name}`);

  return (
    <div className="w-1 grow">
      <TableHeader>Top landing pages</TableHeader>
      {totalLoading ? (
        <TableSkeleton />
      ) : (
        topLandingPages.map((p, i) => (
          <TableRow key={p.url} index={i + 1}>
            <TruncateWithTooltip text={p.url.replace(regex, '')} className="grow" />
            <div className="ml-4 flex w-16 shrink-0 items-center justify-end text-right">
              {round((p.total / total) * 100, 1).toLocaleString()}%
            </div>
          </TableRow>
        ))
      )}
      <Section.SeeMore to="/traffic" />
    </div>
  );
}
