import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SkeletonLine } from '@crazyegginc/hatch';

import { FeatureIcon } from '/src/features/whats-new/components/Event';
import { changeLogQuery } from '/src/features/whats-new/queries';
import { getFeatureName } from '/src/features/_global/utils';
import { externalizeLinks } from '/src/features/whats-new/utils';
import { Section } from '../Section';

import { ReactComponent as WhatsNewIcon } from '@crazyegginc/hatch/dist/images/icon-megaphone-filled.svg';

export function WhatsNewSection() {
  const { data, fetching } = useQuery(changeLogQuery({ newOnly: true }));

  return (
    <Section className="relative overflow-hidden col-end-4 row-span-1 min-h-[300px] [contain:size]">
      <Section.Header icon={WhatsNewIcon} color="text-lavender-500">
        <span>What&apos;s New</span>
      </Section.Header>
      <div className="relative overflow-hidden">
        {fetching ? (
          <div className="relative">
            <SkeletonEntry />
            <SkeletonEntry />
            <SkeletonEntry />
            <SkeletonEntry />
          </div>
        ) : (
          <>
            <div className="relative">
              {data?.changelog?.map((event) => (
                <Event key={`event${event.id}`} event={event} />
              ))}
            </div>
          </>
        )}
      </div>
      <div
        className={classNames(
          'absolute bottom-0 left-0 flex h-28 w-full items-end justify-end rounded-b px-4 pb-2',
          'bg-gradient-to-b from-white/0 via-white-lilac-500/80 to-white-lilac-500/100',
        )}
      >
        <Link to="/new" className="text-link">
          More...
        </Link>
      </div>
    </Section>
  );
}

function Event({ event }) {
  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      externalizeLinks(contentRef.current);
    }
  }, [contentRef]);

  return (
    <Section.Body className="relative !pb-4 !pr-2 !pl-0 border-b border-mystic-500 border-dashed mx-3 mt-5">
      <div className="mb-1 flex">
        <div className="shrink-0 grow-0">
          <FeatureIcon feature={event.category} />
        </div>
        <div className="ml-3 grow space-y-px">
          <div className="text-body-2 line-clamp-1 mb-2">
            <Link to="/new" className="text-body-1 text-dodger-blue-500 hover:underline">
              {getFeatureName(event.category)}: {event.title}
            </Link>
          </div>
          <div
            className="prose prose-lg prose-slate text-body-2 mx-auto line-clamp-2 leading-snug"
            dangerouslySetInnerHTML={{
              __html: event.contentHtml,
            }}
            ref={contentRef}
          />
        </div>
      </div>
    </Section.Body>
  );
}

function SkeletonEntry() {
  return (
    <Section.Body className="relative !pb-4 !pr-2 !pl-0 border-b border-mystic-500 border-dashed mx-3 mt-5">
      <div className="mb-2 flex">
        <div>
          <SkeletonLine className="!h-10 !w-10 rounded-full" />
        </div>
        <div className="ml-3 grow space-y-1">
          <SkeletonLine className="!w-2/3" />
          <SkeletonLine className="!w-1/3" />
        </div>
      </div>
      <SkeletonLine className="mb-1 !w-full" />
      <SkeletonLine className="!w-4/5" />
    </Section.Body>
  );
}
